import type { CalcEventNameType, ProjectType } from '@laicui/types';
import { onBeforeUnmount } from 'vue';

import Core from './Core';

type OnMessageType = (message: MessageEvent) => void;

export default class Message<T extends ProjectType> {
  private eventQueue = new Map<CalcEventNameType<T>, OnMessageType>();

  constructor() {
    onBeforeUnmount(this.onBeforeUnmount.bind(this));
  }

  private onBeforeUnmount() {
    if (this.eventQueue.size <= 0) {
      return;
    }

    this.eventQueue.forEach((callback, keyName) => {
      Core.off(keyName, callback);
    });

    this.eventQueue.clear();
  }

  connect(url: string, params: object) {
    Core.connect(url, params);
  }

  onMessage(eventName: CalcEventNameType<T>, callback: OnMessageType) {
    // 同一个事件在同一个实例中只允许监听一次
    if (this.eventQueue.get(eventName)) {
      return;
    }

    this.eventQueue.set(eventName, callback);
    Core.on(eventName, callback);
  }

  // 关闭监听
  offMessageEvent() {
    this.onBeforeUnmount();
  }
}
