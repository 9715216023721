<template>
  <icon-font
    :name="name"
    :size="size || '16px'"
    :style="{ color: color || 'inherit' }"
    class="iconfont"
    :url="newIconfontUrl"
  />
</template>
<script setup lang="ts" name="Iconfont">
import { IconFont } from 'tdesign-icons-vue-next';

import { iconfontProps } from './iconfontProps';

const newIconfontUrl = import.meta.env.VITE_ICONFONT_URL;

defineProps(iconfontProps);
</script>
