<template>
  <div :class="sideNavCls">
    <!-- 一级菜单 -->
    <t-menu class="top-menu" :class="menuCls" theme="dark" width="124px" :value="active" :collapsed="collapsed">
      <template #logo>
        <span v-if="showLogo" :class="`${prefix}-side-nav-logo-wrapper`" @click="goHome">
          <component :is="getLogo()" :class="[`${prefix}-side-nav-logo-tdesign-logo`, collapsed ? 'collapsed' : '']" />
        </span>
      </template>
      <menu-content :nav-data="parentMenuList" />
    </t-menu>

    <!-- 二三级菜单 -->
    <t-menu
      v-if="childrenMenuList.length > 0"
      :class="childrenMenuCls"
      theme="light"
      width="140px"
      :value="childrenActive"
      :expanded="expandValue"
      expand-mutex
      @expand="handleExpand"
    >
      <menu-content :nav-data="childrenMenuList" :base-path="parentMenuPath" />
    </t-menu>
    <div :class="`${prefix}-side-nav-placeholder${collapsed ? '-hidden' : ''}`"></div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import AssetLogoFull from '@/assets/assets-logo-full.svg?component';
// import AssetLogo from '@/assets/assets-t-logo.svg?component';
import { prefix } from '@/config/global';
import { getActive, getChildrenActive } from '@/router';
import { useSettingStore } from '@/store';
import type { MenuRoute } from '@/types/interface';

// import pgk from '../../../package.json';
import MenuContent from './MenuContent.vue';

const router = useRouter();
const route = useRoute();

const MIN_POINT = 992 - 1;

const props = defineProps({
  menu: {
    type: Array as PropType<MenuRoute[]>,
    default: () => [],
  },
  showLogo: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  isFixed: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  layout: {
    type: String as PropType<string>,
    default: '',
  },
  headerHeight: {
    type: String as PropType<string>,
    default: '64px',
  },
  theme: {
    type: String as PropType<'light' | 'dark'>,
    default: 'light',
  },
  isCompact: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const parentMenuPath = computed(() => {
  return route.matched[0].path;
});

const parentMenuList = computed(() => {
  return props.menu.map((item) => {
    return {
      ...item,
      children: [],
    };
  });
});

const childrenMenuList = computed(() => {
  const parent = props.menu.find((item) => item.name === route.matched[0].name);

  return parent?.children?.filter((item) => item.meta && item.meta.hidden !== true) || [];
});

const collapsed = computed(() => useSettingStore().isSidebarCompact);

const active = computed(() => getActive());
const childrenActive = computed(() => getChildrenActive());

const expandValue = ref([]);

const handleExpand = (value) => {
  expandValue.value = value;
};

watch(
  () => router,
  () => {
    const parentPath = route.matched?.[1]?.path;

    expandValue.value = parentPath ? [parentPath] : [];
  },
  { immediate: true, deep: true },
);

const sideNavCls = computed(() => {
  const { isCompact } = props;
  return [
    `${prefix}-sidebar-layout`,
    {
      [`${prefix}-sidebar-compact`]: isCompact,
      'no-children-menu': childrenMenuList.value.length === 0,
    },
  ];
});

const menuCls = computed(() => {
  const { showLogo, isFixed, layout } = props;
  return [
    `${prefix}-side-nav`,
    {
      [`${prefix}-side-nav-no-logo`]: !showLogo,
      [`${prefix}-side-nav-no-fixed`]: !isFixed,
      [`${prefix}-side-nav-mix-fixed`]: layout === 'mix' && isFixed,
    },
  ];
});

const childrenMenuCls = computed(() => {
  const { isCompact } = props;
  return [
    `${prefix}-children-side-nav`,
    {
      [`${prefix}-children-side-nav-collapsed`]: isCompact,
    },
  ];
});

const settingStore = useSettingStore();

const autoCollapsed = () => {
  const isCompact = window.innerWidth <= MIN_POINT;
  settingStore.updateConfig({
    isSidebarCompact: isCompact,
  });
};

onMounted(() => {
  autoCollapsed();
  window.onresize = () => {
    autoCollapsed();
  };
});

const goHome = () => {
  router.push('/dashboard/base');
};

const getLogo = () => {
  // if (collapsed.value) return AssetLogo;
  return AssetLogoFull;
};
</script>

<style lang="less" scoped>
.top-menu {
  :deep(.t-menu) {
    padding-top: 4px;
  }
}
.tdesign-starter-side-nav-logo-wrapper {
  margin-left: 0 !important;
}
</style>
