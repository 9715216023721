/**
 * @description 建议全局细粒化错误码,catch中单独引入全局的错误码进行判定,方便统一对错误码管理。
 * @example 业务中的catch由原来的 error.code==='A0417' 改为 statusChange[error.code]
 */

export enum statusChange {
  A0417 = '状态已变更',
  A0440 = '状态已变更',
}

export enum serverError {}
export enum insufficientPermission {}

export const commonStatusCode = {
  ...statusChange,
  ...serverError,
  ...insufficientPermission,
} as Record<string, any>;
