import { nextTick } from 'vue';

import { useUserStore } from '@/store';

export type CalcType = 'or' | 'and';

/**
 * 当前权限字符串或数组->是否存在于用户所拥有的权限配置里
 * @param value 需要检查的权限字符串或者数组
 * @param type:CalcType
 * @returns  boolean (权限存在—>true  权限不存在->false)
 */
export const checkPermissions = (value: string | Array<string>, type?: CalcType): boolean => {
  const { permissionList } = useUserStore();
  if (typeof value === 'string') {
    return permissionList.includes(value);
  }
  if (!Array.isArray(value)) {
    console.error('权限参数必须为string或者array');
    return false;
  }
  if (type && !['and', 'or'].includes(type)) {
    console.error("匹配规则必须为 'and' 或 'or',不传默认为 'or' ");
    return false;
  }

  if (!value.length) return false;

  if (!type || type === 'or') {
    return value.some((v) => permissionList.includes(v));
  }

  return value.every((v) => permissionList.includes(v));
};

export const setElementState = (el, isHas: boolean | null) => {
  if (isHas === null || isHas) return;
  nextTick(() => {
    if (el.parentNode) {
      el.parentNode.removeChild(el);
      return;
    }
    el.style.display = 'none';
  });
};
