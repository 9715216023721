import 'uno.css';
import 'tdesign-vue-next/es/style/index.css';
import '@/style/index.less';
import 'default-passive-events';
import './permission';

import { watchWebsiteUpate } from '@laicui/utils';
import TDesign from 'tdesign-vue-next';
import { createApp } from 'vue';

import App from './App.vue';
import { setGlobalDirecitive } from './directive';
import router from './router';
import { store } from './store';

const app = createApp(App);
app.use(TDesign);
app.use(store);
watchWebsiteUpate();
setGlobalDirecitive(app);
app.use(router);

app.mount('#app');
