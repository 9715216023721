import { request } from '@/utils/request';

const Api = {
  LoginByPhone: '/non-auth/user/phone/login',
  UserInfo: '/api/user/info',
  SendSmsCode: '/non-auth/sms',
  QyWechatLogin: '/non-auth/user/third/login',
  GenerateShortToken: '/api/sys/user/manage/short/token/generating',
  UnbindQyWechat: '/api/sys/user/manage/qy/wechat/login/unbind',
  GetUserInfo: '/api/sys/user/manage/userinfo',
  SendUpdatePhoneSmsCode: '/api/sys/user/manage/bind-change/sms/send',
  UpdatePhone: '/api/sys/user/manage/phone/bind-change',
};

export function loginByPhone(userInfo) {
  return request.post({
    url: Api.LoginByPhone,
    data: userInfo,
  });
}

export function getUserInfo() {
  return request.get({
    url: Api.UserInfo,
  });
}

export function GetUserInfo() {
  return request.get({
    url: Api.GetUserInfo,
  });
}

export function sendSmsCode(phone: string) {
  return request.post({
    url: Api.SendSmsCode,
    params: `/${phone}`,
  });
}

export type QyWechatLoginDataType = {
  code: string;
  thirdType?: string;
};

export function QyWechatLogin(data: QyWechatLoginDataType) {
  return request.post({
    url: Api.QyWechatLogin,
    data,
  });
}

export function GenerateShortToken() {
  return request.post({
    url: Api.GenerateShortToken,
  });
}

export function UnbindQyWechat() {
  return request.post({
    url: Api.UnbindQyWechat,
  });
}

export function SendUpdatePhoneSmsCode(phone: string) {
  return request.get({
    url: Api.SendUpdatePhoneSmsCode,
    params: `/${phone}`,
  });
}

export type UpdatePhoneDataType = {
  phone: string;
  code: string;
};

export function UpdatePhone(data: UpdatePhoneDataType) {
  return request.put({
    url: Api.UpdatePhone,
    data,
  });
}
