<template>
  <div class="menu-content">
    <template v-for="(item, index) in list" :key="item.path">
      <template v-if="!item.children || !item.children.length || item.meta?.single">
        <t-menu-item v-if="getHref(item)" :name="item.path" :value="getPath(item)" @click="openHref(getHref(item)[0])">
          <template #icon>
            <component :is="menuIcon(item)" :class="typeof item.icon === 'string' ? 'l-icon' : 't-icon'"></component>
          </template>
          <div class="menu-item-title" :class="basePath ? 'children' : ''">
            <span>{{ item.title }}</span>
          </div>
        </t-menu-item>
        <t-menu-item v-else :name="item.path" :value="getPath(item)" :to="item.path">
          <template #icon>
            <component :is="menuIcon(item)" :class="typeof item.icon === 'string' ? 'l-icon' : 't-icon'"></component>
          </template>
          <div class="menu-item-title" :class="basePath ? 'children' : ''">
            <span>{{ item.title }}</span>
          </div>
        </t-menu-item>
      </template>
      <t-submenu v-else :name="item.path" :value="item.path">
        <template #icon>
          <component :is="menuIcon(item)" :class="typeof item.icon === 'string' ? 'l-icon' : 't-icon'"></component>
        </template>
        <template #title>
          <div class="sub-menu-title">
            <span>{{ item.title }}</span>
          </div>
        </template>
        <menu-content v-if="item.children" :nav-data="item.children" />
        <t-divider v-if="index < list.length - 1" />
      </t-submenu>
    </template>
  </div>
</template>
<script setup lang="tsx">
import type { PropType } from 'vue';
import { computed } from 'vue';

import IconFont from '@/components/IconFont/index.vue';
import { getChildrenActive } from '@/router';
import type { MenuRoute } from '@/types/interface';

type ListItemType = MenuRoute & { icon?: string };

const props = defineProps({
  navData: {
    type: Array as PropType<MenuRoute[]>,
    default: () => [],
  },
  basePath: {
    type: String,
    default: '',
  },
});

const childrenActive = computed(() => getChildrenActive());

const list = computed(() => {
  const { navData } = props;
  return getMenuList(navData, props.basePath);
});

const menuIcon = (item: ListItemType) => {
  if (typeof item.icon === 'string') return <IconFont name={item.icon} />;
  const RenderIcon = item.icon;
  return RenderIcon;
};

const getMenuList = (list: MenuRoute[], basePath?: string): ListItemType[] => {
  if (!list || list.length === 0) {
    return [];
  }
  // 如果meta中有orderNo则按照从小到大排序
  // 注释原因：后端返回的时候进行了排序
  // list.sort((a, b) => {
  //   return (a.meta?.orderNo || 0) - (b.meta?.orderNo || 0);
  // });
  return list
    .map((item) => {
      const path = basePath && !item.path.includes(basePath) ? `${basePath}/${item.path}` : item.path;

      return {
        path,
        title: item.meta?.title,
        icon: item.meta?.icon,
        children: getMenuList(item.children, path),
        meta: item.meta,
        redirect: item.redirect,
      };
    })
    .filter((item) => item.meta && item.meta.hidden !== true);
};

const getHref = (item: MenuRoute) => {
  const { frameSrc, frameBlank } = item.meta;
  if (frameSrc && frameBlank) {
    return frameSrc.match(/(http|https):\/\/([\w.]+\/?)\S*/);
  }
  return null;
};

const getPath = (item: ListItemType) => {
  if (!props.basePath) {
    return item.path;
  }
  const activeLevel = childrenActive.value.split('/').length;
  const pathLevel = item.path.split('/').length;

  if (activeLevel > pathLevel && childrenActive.value.startsWith(item.path)) {
    return childrenActive.value;
  }

  if (childrenActive.value === item.path) {
    return childrenActive.value;
  }

  return item.meta?.single ? item.redirect : item.path;
};

const openHref = (url: string) => {
  window.open(url);
};
</script>

<style lang="less" scoped>
.menu-content {
  :deep(.t-menu__sub .t-menu__item) {
    padding: 0 0 0 10px;

    .menu-item-title {
      padding-left: 6px;
    }
  }

  .menu-item-title {
    padding-left: 8px;
    &.children {
      padding-left: 0;
      width: 84px;
      display: flex;
      justify-content: space-between;
    }
  }

  .sub-menu-title {
    width: 70px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
