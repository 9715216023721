import type { App, Directive } from 'vue';

import type { CalcType } from '../hooks/permissions';
import { checkPermissions, setElementState } from '../hooks/permissions';
/**
 * 默认：v-permission="[]"  v-permission="'xx'"
 * 指令参数：or | and 不传则默认or
 * v-permission:or="[]" v-permission:and="[]"
 */
export const permission: Directive = {
  created: (el, { value, arg }) => {
    setElementState(el, checkPermissions(value, arg as CalcType));
  },
  updated: (el, { value, arg }) => {
    setElementState(el, checkPermissions(value, arg as CalcType));
  },
};

type DirectiveObject = {
  [key: string]: Directive;
};

// 需要全局注册的指令,不是必须的
const global: DirectiveObject = {
  permission,
};

export const setGlobalDirecitive = (app: App) => {
  Object.keys(global).forEach((key) => {
    app.directive(key, global[key]);
  });
};

// ###若按需导入指令，则需使用别名： https://cn.vuejs.org/api/sfc-script-setup.html#using-custom-directives
