import { objectToQueryString } from '@laicui/utils';

class Core<T extends string> {
  private eventSource: EventSource;

  connect(url: string, params: object) {
    if (this.eventSource) {
      this.close();
    }

    const paramsToString = objectToQueryString(params);
    this.eventSource = new EventSource(`${url}?${paramsToString}`, {
      withCredentials: true,
    });

    this.bindEvent();
  }

  private bindEvent() {
    this.eventSource.onerror = this.onError.bind(this);
    this.eventSource.onopen = this.onOpen.bind(this);
    this.eventSource.addEventListener('sse_init', this.SSEinit.bind(this));
  }

  on(eventName: T, callback: (event: MessageEvent) => void) {
    this.eventSource.addEventListener(eventName, callback);
  }

  off(eventName: T, callback: (event: MessageEvent) => void) {
    this.eventSource.removeEventListener(eventName, callback);
  }

  private onOpen(event) {
    // open会在任意消息首条推送时触发 且全局只触发一次
    console.log('onOpen,sse已连接', event);
  }

  private SSEinit(evnet: MessageEvent) {
    // 此init触发代表握手成功
    console.log('sse_init', evnet);
  }

  private onError(event) {
    console.log('onError', event);
  }

  private close() {
    this.eventSource.close();
    this.eventSource = null;
  }
}
export default new Core();
