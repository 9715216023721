import { ISaasEventName } from '@laicui/constants';
import type { SaasType } from '@laicui/types';
import SSE from '@laicui/utils/sse/index';
import { defineStore } from 'pinia';

import { useUserStore } from './user';

const { VITE_SSE_URL } = import.meta.env;

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    IMServiceUnreadNum: 0,
  }),
  getters: {},
  actions: {
    init() {
      const sseMessage = new SSE<SaasType>();
      const { userInfo } = useUserStore();

      if (!userInfo.userId || !localStorage.getItem('token')) {
        return;
      }

      sseMessage.connect(VITE_SSE_URL, {
        annoUserId: userInfo.userId,
      });

      sseMessage.onMessage(ISaasEventName.imUnreadNum, (event) => {
        console.log('push_srm_im_unread_num', event);
        const num = event.data;
        this.IMServiceUnreadNum = num;
      });
    },

    setUnreadNum(value: number) {
      this.unreadNum = value;
    },
    setIMServiceUnreadNum(value: number) {
      this.IMServiceUnreadNum = value;
    },
  },
});
