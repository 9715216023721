import TIM, { ChatSDK, Message } from '@tencentcloud/chat';
import TIMUploadPlugin from 'tim-upload-plugin';

import { SendMessagOptions, TIMCoreInitParams, TIMloginOptions } from './index.type';

export default class TIMCore {
  static instance: TIMCore;

  public tim: ChatSDK;

  public TIM = TIM;

  private isSDKReady = false;

  public userID: string;

  // 平台类型
  public platformType: 'platform' | 'srm';

  // 记录因加载时机未被回调的waitForSdkReadyCall
  private waitForCallbackMap: Map<string, Function> = new Map();

  constructor(params: TIMCoreInitParams) {
    const { platformType, ...extendsParams } = params;
    this.platformType = platformType;
    this.initTimSDK(extendsParams);
  }

  // 单例 通过init初始化
  private static init(options: TIMCoreInitParams) {
    if (!TIMCore.instance) {
      TIMCore.instance = new TIMCore(options);
    }
    return TIMCore.instance;
  }

  static getInstance(options: TIMCoreInitParams): TIMCore {
    return this.init(options);
  }

  private async initTimSDK(options: Omit<TIMCoreInitParams, 'platformType'>) {
    // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
    const chat = TIM.create(options);
    chat.setLogLevel(1); // 普通级别，日志量较多，接入时建议使用
    // chat.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用

    // 注册腾讯云即时通信 IM 上传插件
    chat.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });
    this.tim = chat;
    console.log('SDK初始化成功');

    this.bindTIMEvent();
  }

  private bindTIMEvent() {
    this.tim.on(TIM.EVENT.SDK_READY, this.handleSDKReady, this);
    this.tim.on(TIM.EVENT.ERROR, this.onTimError, this);
    this.tim.on(TIM.EVENT.NET_STATE_CHANGE, this.onNetWorkError, this);
  }

  public unbindTIMEvent() {
    this.tim.off(TIM.EVENT.SDK_READY, this.handleSDKReady, this);
  }

  private handleSDKReady() {
    this.isSDKReady = true;
    console.log('SDK 准备就绪 可以调用基于权限的API');
    this.handlingLostCallback();
  }
  /**
   * 确保sdk就绪后调用callback
   * @param callback 调用的方法
   */

  public waitForSdkReadyCall(callback: any) {
    if (this.isSDKReady) {
      callback();
    } else {
      this.waitForCallbackMap.set('watingCallback', callback);
    }
  }

  private handlingLostCallback() {
    if (this.waitForCallbackMap.size > 0) {
      const callback = this.waitForCallbackMap.get('watingCallback');
      this.waitForCallbackMap.delete('watingCallback');
      console.log('处理遗失的回调');
      if (typeof callback === 'function') callback();
    }
  }

  public async TIMlogin(params: TIMloginOptions) {
    await this.tim.login(params);
    this.userID = params.userID;
    this.setLoginInfo(params);
  }

  public setLoginInfo(info: TIMloginOptions) {
    localStorage.setItem('tim-login-info', JSON.stringify(info));
  }

  public getLoginInfo(): TIMloginOptions {
    return JSON.parse(localStorage.getItem('tim-login-info') || '{}');
  }

  public createTextMessage(options: SendMessagOptions): Message {
    return this.tim?.createTextMessage({
      conversationType: TIM.TYPES.CONV_C2C,
      needReadReceipt: true,
      ...options,
      cloudCustomData: this.handleCustomData(options.cloudCustomData),
    });
  }

  public createImageMessage(options: SendMessagOptions): Message {
    return this.tim?.createImageMessage({
      conversationType: TIM.TYPES.CONV_C2C,
      needReadReceipt: true,
      ...options,
      cloudCustomData: this.handleCustomData(options.cloudCustomData),
      onProgress(res: any) {
        console.log('图片上传进度', res);
      },
    });
  }

  public createVideoMessage(options: SendMessagOptions) {
    const message = this.tim?.createVideoMessage({
      conversationType: TIM.TYPES.CONV_C2C,
      needReadReceipt: true,
      ...options,
      cloudCustomData: this.handleCustomData(options.cloudCustomData),
      onProgress: (progress: number) => {
        this.handleUploadProgress(progress, message);
      },
    });
    return message;
  }

  // 创建自定义消息
  public createCustomMessage(
    options: SendMessagOptions<{
      data: string;
      description: string;
      extension: string;
    }>,
  ): Message {
    return this.tim?.createCustomMessage({
      conversationType: TIM.TYPES.CONV_C2C,
      needReadReceipt: true,
      ...options,
      cloudCustomData: this.handleCustomData(options.cloudCustomData),
    });
  }

  // 由外部实现（这里后续需要采用发布订阅，因为当前类是单例全局共享的，此函数会被不同的实现覆盖）
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleUploadProgress(_progress: number, _message: Message) {}

  private handleCustomData(data?: object): string {
    const sendType = {
      sendType: this.platformType === 'srm' ? 'supplier' : 'platform',
    };
    if (!data) {
      return JSON.stringify(sendType);
    }
    return JSON.stringify({
      ...data,
      // 发送人类型；platform=平台，supplier=供应商，user=用户"
      ...sendType,
    });
  }

  logOut() {
    const userID = this.tim.getLoginUser();
    if (userID) {
      this.tim.logout();
    }
  }

  private onTimError(event) {
    console.log('监听sdk错误', event);
  }

  private onNetWorkError(event) {
    console.log('监听网络状态改变', event);
  }
}
