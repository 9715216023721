import { AfterSalesAuditStatusType } from '@laicui/types';

export const ADTER_SALES_TYPE_REASON_LABEL_ENUM = {
  user_refund: '退款原因',
  warranty: '保修原因',
};

export const AFTER_SALES_REFUND_TYPE_ENUM = {
  refund: '仅退款',
  refundAndReturns: '退货退款',
};

export const AFTER_SALES_STATUS_TEXT_ENUM: { [key in AfterSalesAuditStatusType]: string } = {
  wait_audit: '待处理',
  wait_undo: '撤销申请',
  rejected: '已拒绝',
  pending_shipment: '买家待发货',
  shipment_undo: '撤销申请',
  pending_sign: '退货中',
  signing_reject: '已拒绝',
  servicing: '维修中',
  refunding: '退款中',
  finish: '已完成',
};

export const AFTER_SALES_STEP_AUDIT_TITLE_ENUM = {
  wait: '待处理',
  success: '审核通过',
  reject: '已拒绝',
};

export const AFTER_SALES_AUDIT_RESULT_TEXT_ENUM = {
  refundAndReturns: {
    success: '同意退货',
    reject: '拒绝退货',
  },
  refund: {
    success: '同意退款',
    reject: '拒绝退款',
  },
  warranty: {
    success: '同意保修',
    reject: '拒绝保修',
  },
};

export const AFTER_SALES_STATUS_THEME_ENUM: { [key in AfterSalesAuditStatusType]: string } = {
  wait_audit: 'error',
  wait_undo: 'default',
  rejected: 'error',
  pending_shipment: 'warning',
  shipment_undo: 'default',
  pending_sign: 'success',
  signing_reject: 'error',
  servicing: 'success',
  refunding: 'success',
  finish: 'default',
};
