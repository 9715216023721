export enum ISrmEventName {
  // 全局消息
  messageUnreadNum = 'push_srm_sys_message_unread_num',
  // '客服消息';
  imUnreadNum = 'push_srm_im_unread_num',
  qrScanLogin = 'push_srm_login_event',
  // 充值成功消息
  accountRechargeSuccess = 'push_srm_market_account_recharge_success',
}

export enum ISaasEventName {
  imUnreadNum = 'push_saas_im_unread_num',
}
