import { defineStore } from 'pinia';

import { getUserInfo, loginByPhone, QyWechatLogin, QyWechatLoginDataType } from '@/api/user';
import { TOKEN_NAME } from '@/config/global';
import { store, usePermissionStore } from '@/store';

const InitUserInfo = {
  account: '',
  realname: '',
  avatar: '',
  userStatus: '',
  permissionList: [],
  userId: '',
};

export const useUserStore = defineStore('user', {
  state: () => ({
    token: localStorage.getItem(TOKEN_NAME) || '',
    userInfo: { ...InitUserInfo },
  }),
  getters: {
    permissionList: (state) => {
      return state.userInfo?.permissionList;
    },
  },
  actions: {
    async login(userInfo: Record<string, unknown>) {
      const res = await loginByPhone(userInfo);
      this.token = res.data.token;
      localStorage.setItem(TOKEN_NAME, res.data.token);
    },
    async qyWechatLogin(params: QyWechatLoginDataType) {
      const res = await QyWechatLogin(params);
      this.token = res.data.token;
      localStorage.setItem(TOKEN_NAME, res.data.token);
    },
    async getUserInfo() {
      const res = await getUserInfo();
      this.userInfo = res.data;
      return res.data;
    },
    async logout() {
      localStorage.removeItem(TOKEN_NAME);
      this.token = '';
      this.userInfo = { ...InitUserInfo };
    },
    async removeToken() {
      this.token = '';
    },
  },
  persist: {
    afterRestore: () => {
      const permissionStore = usePermissionStore();
      permissionStore.initRoutes();
    },
  },
});

export function getUserStore() {
  return useUserStore(store);
}
