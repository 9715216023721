import dayjs from 'dayjs';

const titleStyle = {
  fontSize: '14px',
  color: '#333',
};
const contentStyle = {
  color: '#0052d9',
  fontWeight: 400,
  fontSize: '14px',
};
const mode = import.meta.env.MODE;
export default async () => {
  const getLogBtn = async () => {
    const sourceAddress = window.location.origin;
    const data = await fetch(`${sourceAddress}/commit.json`, { cache: 'no-store' });
    const json = await data.json();
    const { DialogPlugin } = await import('tdesign-vue-next');
    const list = json[0]?.all || [];

    DialogPlugin({
      header: '更新日志',
      width: 800,
      cancelBtn: null,
      confirmBtn: null,
      body: () => {
        return (
          <ul style="max-height:50vh;overflow: auto;">
            {list.map((v) => {
              return (
                <li>
                  <div>
                    <h3>
                      <span style={titleStyle}>提交内容：</span> <span style={contentStyle}>{v.message}</span>
                    </h3>
                  </div>
                  <div>
                    <h4>
                      提交人： <span>{v.author_name}</span>
                    </h4>
                  </div>
                  <div>
                    <h4>
                      提交账户： <span>{v.author_email}</span>
                    </h4>
                  </div>
                  <div>
                    <h4>
                      提交时间： <span>{dayjs(v.date).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </h4>
                  </div>
                  <hr style=" height:1px;border:none;border-top:1px solid black;margin:20px 0" />
                </li>
              );
            })}
          </ul>
        );
      },
    });
  };

  return (
    <div slot="footer">
      <div class="t-notification__detail">
        <a class="t-notification__detail-item" style="padding:5px" onClick={() => window.location.reload()}>
          立即刷新
        </a>
        {mode === 'test' && (
          <a class="t-notification__detail-item" style="padding:5px" onClick={getLogBtn}>
            更新日志
          </a>
        )}
      </div>
    </div>
  );
};
