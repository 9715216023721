import { useDocumentVisibility } from '@vueuse/core';
import { h, watch } from 'vue';

import FooterUpdateWebBtn from './FooterUpdateWebBtn';

export const watchWebsiteUpate = async () => {
  const footerRender = h(await FooterUpdateWebBtn());
  const { NotifyPlugin } = await import('tdesign-vue-next');
  const worker = new Worker(new URL('./worker.ts', import.meta.url).href, {});
  const pageVisibility = useDocumentVisibility();
  watch(
    () => pageVisibility.value,
    () => {
      worker.postMessage({
        pageVisibility: pageVisibility.value,
      });
    },
  );

  worker.postMessage({
    isFirstLoad: true,
  });
  worker.onmessage = async (event) => {
    const { isUpdata } = event.data;
    if (isUpdata) {
      NotifyPlugin.info({
        title: '页面更新提示',
        content: '网页内容有更新，请刷新页面',
        duration: 0,
        closeBtn: true,
        footer: () => footerRender,
      });
      // 及时关闭线程任务
      worker.terminate();
    }
  };
};
