import type { MenuListResult } from '@/api/model/permissionModel';
import { request } from '@/utils/request';

const Api = {
  MenuList: '/api/menu/list',
  GetPermissionTree: '/sys/permission/list',
  CreatePermission: '/sys/permission/add',
  UpdatePermission: '/sys/permission/edit',
  DeletePermission: '/sys/permission/delete',
  SortPermission: '/sys/permission/sort',
};

export function getMenuList() {
  return request.get<MenuListResult>({
    url: Api.MenuList,
  });
}

export function getPermissionTree(params = {}) {
  return request.get({
    url: Api.GetPermissionTree,
    params,
  });
}

export function createPermission(data) {
  return request.post({
    url: Api.CreatePermission,
    data,
  });
}

export function updatePermission(data) {
  return request.post({
    url: Api.UpdatePermission,
    data,
  });
}

export function deletePermission(permId) {
  return request.delete({
    url: Api.DeletePermission,
    params: `?id=${permId}`,
  });
}

export function sortPermission(data) {
  return request.post({
    url: Api.SortPermission,
    data,
  });
}
